<template>
  <div class="paypal-page" id="paypal-button-container">
    <!-- {{ $route.query }} -->
    <!-- <div class="btn" @click="paySuccess">支付成功</div>
    <div class="btn" @click="payFail">支付失败</div> -->
  </div>
</template>

<script>
import { CommonModule } from '@/store/modules/common'
import {
  appFunc_senToken,
  appFunc_payFail,
  appFunc_paySuccess
} from '@/utils/bridge'
import {
  apiCreateOrder,
  apiPayNotify,
  apiGetAccessToken,
  apiPayPalCreateOrder,
  apiCaptureErrorOrder
} from '@/api'
import { clientId } from '@/config'
import { loadScript } from '@paypal/paypal-js'

export default {
  data() {
    return {
      orderInfo: {}
    }
  },
  methods: {
    async getToken() {
      const res = await appFunc_senToken()
      console.log(res, '/////////////')
      CommonModule.setUserId(res.id)
      CommonModule.setToken(res.tokenForJs)
    },
    paySuccess() {
      appFunc_paySuccess()
    },
    payFail() {
      appFunc_payFail({
        orderId: '123',
        msg: '测试-支付失败'
      })
    },
    async createOrder(params) {
      console.log('创建订单回调', params)
      if (!this.$route.query.rechargeConfigId) {
        console.log('无钻石规则id')
        return
      }
      const tokenRes = await apiGetAccessToken()
      if (tokenRes.code != 1001) return
      const paypalRes = await apiPayPalCreateOrder(
        {
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.$route.query.amount
              }
            }
          ],
          intent: 'CAPTURE'
        },
        tokenRes.data
      )
      if (paypalRes.status != 201) {
        console.log('paypal创建订单失败')
        return
      }
      console.log(paypalRes.data, '-----------------')
      const { code, data } = await apiCreateOrder({
        rechargeConfigId: this.$route.query.rechargeConfigId,
        tradePlatform: 4
      })
      console.log(code, data.payOrderId)

      if (paypalRes.data.status === 'CREATED' && code === 1001) {
        this.orderInfo = data
        return paypalRes.data.id
      }
    },
    async payApprove(params) {
      console.log('支付成功回调', params)
      // const tokenRes = await apiGetAccessToken()
      // if (tokenRes.code != 1001) return
      // const errRes = await apiCaptureErrorOrder(params.orderID, tokenRes.data)
      // console.log(errRes, '-----------------------------------errRes')
      const { code, data } = await apiPayNotify({
        payOrderId: this.orderInfo.payOrderId,
        payProductId: this.$route.query.rechargeConfigId,
        payType: 'paypal',
        tradeNo: params.orderID,
        uid: CommonModule.userId
      })
      console.log(code, data)
      if (code === 1001) {
        appFunc_paySuccess(this.orderInfo.payOrderId)
      }
    },
    payCancel(params) {
      apiPayNotify({
        payOrderId: this.orderInfo.payOrderId,
        payProductId: this.$route.query.rechargeConfigId,
        payType: 'paypal',
        tradeNo: params.orderID,
        uid: CommonModule.userId
      })
    },
    payError(err) {
      console.log('支付失败回调', err)
      appFunc_payFail({
        orderId: this.orderInfo.payOrderId,
        msg: err
      })
    },
    initPaypal() {
      loadScript({
        'client-id': clientId,
        // 'AXTOJj0f5wg9nnUvI6fu2MeAFsPnMflQ28IJvTPdXY8hKqUDp9HK-CejtmAue9lE5lXDoGKCJmAg38qU',
        // 'AbT_5209-P20Kp7H-CHhpJUkibfxy6GkOZCyDqXnKnNXn87UHsAnJiTog3ptDfJkjQzHmUXK4e5J3YTM',
        components: 'buttons',
        locale: 'ar_SA'
      })
        .then(paypal => {
          console.log(paypal)
          paypal
            .Buttons({
              style: {
                borderRadius: 10
              },
              createOrder: this.createOrder,
              onApprove: this.payApprove,
              onCancel: this.payCancel,
              onError: this.payError
            })
            .render('#paypal-button-container')
        })
        .catch(err => {
          console.log('初始化失败', err)
        })
    }
  },
  async created() {
    this.getToken()
    this.initPaypal()
  }
}
</script>

<style lang="scss" scoped>
.paypal-page {
  width: 300px;
  margin: 50px auto;
  .btn {
    font-size: 20px;
    width: 300px;
    height: 40px;
    line-height: 40px;
    background-color: plum;
    margin: 20px auto 0;
    text-align: center;
  }
}
</style>
