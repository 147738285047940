















































import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import GoldbeanExchange from './components/GoldbeanExchange.vue'
import GoldenbeanHarvest from './components/GoldenbeanHarvest.vue'
@Component({
  name: 'inventory',
  components: {
    GoldbeanExchange,
    GoldenbeanHarvest
  }
})
export default class Inventory extends Vue {
  active = 1
  async created() {
    // 获取当前语言
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }

  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language
  }

  // 返回
  handelBack() {
    this.$router.back()
  }
}
