































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getExchangeDetailList } from '@/api'
import moment from 'moment'
@Component({
  name: 'GoldbeanExchange',
  filters: {
    dateFormat: (value: any, pattern: any) => {
      return moment(value).format(pattern)
    }
  }
})
export default class GoldenbeanHarvest extends Vue {
  [x: string]: any
  // 当前语言
  @Prop({
    type: String,
    default: 'ug'
  })
  language!: string

  // 金豆清单列表
  list: any = []

  emptyImage = require('@/assets/harvest_nodata.png')

  // 是否处在加载状态
  loading = false

  // 是否已加载完成
  finished = false

  // 是否加载失败
  error = false

  // 分页
  pageNum = 1

  // 每页条数
  pageSize = 20

  // 数据总条数
  total = 0

  mounted() {
    this.getExchangeDetailList()
  }

  async getExchangeDetailList() {
    const { data } = await getExchangeDetailList({
      pageNum: this.pageNum,
      pageSize: this.pageSize
    })
    if (data.list.length == 0 || data.list == null) {
      // 判断获取数据条数若等于0
      this.list = [] // 清空数组
      this.finished = true // 停止加载
    }
    // 若数据条数不等于0
    this.total = data.total // 给数据条数赋值
    this.list.push(...data.list) // 将数据放入list中
    this.loading = false // 加载状态结束
    // 如果list长度大于等于总数据条数,数据全部加载完成
    if (this.list.length >= data.total) {
      this.finished = true // 结束加载状态
    }
  }

  // 被 @load调用的方法
  onLoad() {
    const timer = setTimeout(() => {
      this.getExchangeDetailList() // 调用上面方法,请求数据
      this.pageNum++ // 分页数加一
      this.finished && clearTimeout(timer) //清除计时器
    }, 100)
  }
  // 加载失败调用方法
  onRefresh() {
    this.finished = false // 清空列表数据
    this.loading = true // 将 loading 设置为 true，表示处于加载状态
    this.pageNum = 1 // 分页数赋值为1
    this.list = [] // 清空数组
    this.onLoad() // 重新加载数据
  }

  get auditStatus() {
    return (value: any) => {
      return value == 0
        ? '审核中'
        : value == 1
        ? '已打款'
        : value == 2
        ? '审核失败'
        : '打款失败'
    }
  }

  get Style() {
    return (value: any) => {
      return value == 0
        ? { color: '#3D90F2' }
        : value == 1
        ? { color: '#181818' }
        : value == 2
        ? { color: '#E93125' }
        : { color: '#888888' }
    }
  }
}
