import { loadScript } from '@paypal/paypal-js'
import { clientId } from '@/config'
import { CommonModule } from '@/store/modules/common'

import {
  apiCreateOrder,
  apiPayNotify,
  apiGetAccessToken,
  apiPayPalCreateOrder,
  apiMarkFailReason
} from '@/api'
import { debounce } from '@/utils/index'

export default {
  data() {
    return {
      // payWays: ['paypal', 'card'],
      payWays: [],
      paypalOrderInfo: {},
      btnStyle: {
        paypal: {
          color: 'gold'
        },
        card: {
          color: 'black'
        }
      }
    }
  },
  created() {
    // this.initPaypal()
  },
  methods: {
    // 初始化paypal
    async initPaypal() {
      let paypal
      try {
        paypal = await loadScript({
          'client-id': clientId,
          components: 'buttons,funding-eligibility',
          // locale: this.$i18n.locale === 'turki' ? 'en_TR' : 'ar_SA',
          'disable-funding': 'paylater'
        })
      } catch (error) {
        console.error('failed to load the PayPal JS SDK script', error)
      }

      if (paypal) {
        try {
          // paypal.Marks().render('#payment-paypal')
          // paypal.CardFields({
          //   style: {
          //     input: {
          //       color: 'red'
          //     }
          //   }
          // })
          this.payWays.forEach(item => {
            const button = paypal.Buttons({
              fundingSource: item,
              style: {
                borderRadius: 10,
                ...this.btnStyle[item]
              },
              createOrder: this.createOrder,
              onApprove: this.payApprove,
              onCancel: this.payCancel,
              onError: this.payError
            })
            if (button.isEligible()) {
              button.render(`#payment-${item}`)
            }
          })
        } catch (error) {
          console.error('failed to render the PayPal Buttons', error)
        }
      }
    },
    // 创建订单
    async createOrder(params) {
      console.log('创建订单回调', params, params.paymentSource)
      this.reportPayClick(params.paymentSource)
      const tokenRes = await apiGetAccessToken()
      if (tokenRes.code != 1001) return
      const paypalRes = await apiPayPalCreateOrder(
        {
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.ruleList[this.currentIndex].money
              }
            }
          ],
          intent: 'CAPTURE'
        },
        tokenRes.data
      )
      if (paypalRes.status != 201) {
        console.log('paypal创建订单失败')
        return
      }
      // console.log(paypalRes.data, '-----------------')
      const { code, data } = await apiCreateOrder({
        rechargeConfigId: this.ruleList[this.currentIndex].id,
        tradePlatform: params.paymentSource === 'paypal' ? 4 : 5,
        pageFrom: this.$route.query.pageform || ''
      })
      // console.log(code, data.payOrderId)

      if (paypalRes.data.status === 'CREATED' && code === 1001) {
        this.paypalOrderInfo = data
        this.reportDialogShow(params.paymentSource)
        return paypalRes.data.id
      }
    },
    // 支付成功
    async payApprove(params) {
      console.log('支付成功回调', params)
      // const tokenRes = await apiGetAccessToken()
      // if (tokenRes.code != 1001) return
      // const errRes = await apiCaptureErrorOrder(params.orderID, tokenRes.data)
      // console.log(errRes, '-----------------------------------errRes')
      const { code, data } = await apiPayNotify({
        payOrderId: this.paypalOrderInfo.payOrderId,
        payProductId: this.ruleList[this.currentIndex].id,
        payType: 'paypal',
        tradeNo: params.orderID,
        uid: CommonModule.userId || '8875'
      })
      console.log(code, data)
      if (code === 1001) {
        this.isPaySuccess = true
        this.$toast({
          message: this.$t('支付成功，钻石将在3分钟内充值到账'),
          duration: 5000
        })
        this.getRuleList()
        this.reportPaySuccess(params.paymentSource)
      }
    },
    // 取消支付
    payCancel(params) {
      apiPayNotify({
        payOrderId: this.paypalOrderInfo.payOrderId,
        payProductId: this.ruleList[this.currentIndex].id,
        payType: 'paypal',
        tradeNo: params.orderID,
        uid: CommonModule.userId
      })
      apiMarkFailReason({
        failReason: '用户取消支付',
        orderNo: this.paypalOrderInfo.payOrderId
      })
    },
    // 支付失败
    payError(err) {
      console.log('支付失败回调', err.message)
      if (this.paypalOrderInfo.payOrderId) {
        apiMarkFailReason({
          failReason: err.message,
          orderNo: this.paypalOrderInfo.payOrderId
        })
      }
    }
  }
}
