<template>
  <div :class="[
    'diamond-record',
    $languageDir,
    (!incomes || !incomes.length) && 'diamond-record-empty'
  ]">
    <!-- 导航栏 -->
    <van-sticky>
      <van-nav-bar @click-right="handelBack">
        <template #title>
          <span :class="{ ug: language == 'ug' }">{{ $t('记录') }}</span>
        </template>

        <template #right>
          <img class="back-icon" src="@/assets/icon_back.png" alt="" />
        </template>
      </van-nav-bar>

      <div class="tabs">
        <van-tabs v-model="active" line-width="50%" line-height="2" title-active-color="#181818"
          title-inactive-color="#888888">
          <van-tab v-for="(item, index) in recordList" :title="$t(item)" :key="index">
          </van-tab>
        </van-tabs>
      </div>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div v-if="!active">
        <template v-if="incomes && depletes.length">
          <div class="diamond-date-item" v-for="dateItem in depletes" :key="dateItem.day">
            <div class="diamond-date">{{ dateItem.day }}</div>
            <div class="diamond-list-wrap">
              <div class="diamond-list-item" v-for="(item, index) in dateItem.recordList" :key="index">
                <div class="item-left">
                  <div class="count">
                    {{ item.type === 1 ? '+' : '-' }}{{ item.amount }}
                  </div>
                  <div class="balance">
                    <!-- <span>{{ $t('余额') }}：</span>
                      {{ item.balance }} -->
                  </div>
                </div>
                <div class="item-right">
                  <div class="content">{{ item.contentUg }}</div>
                  <div class="time">
                    {{ new Date(item.createTime).toLocaleTimeString() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="diamond-empty" v-else>
          <img class="diamond-empty-img" src="@/assets/diamond/empty.png" />
          <p>{{ $t('暂无钻石消耗记录') }}</p>
        </div>
      </div>
      <div v-else>
        <template v-if="incomes && incomes.length">
          <div class="diamond-date-item" v-for="dateItem in incomes" :key="dateItem.day">
            <div class="diamond-date">{{ dateItem.day }}</div>
            <div class="diamond-list-wrap">
              <div class="diamond-list-item" v-for="(item, index) in dateItem.recordList" :key="index">
                <div class="item-left">
                  <div class="count">
                    {{ item.type === 1 ? '+' : '-' }}{{ item.currencySymbol + item.price }}
                  </div>
                  <div class="balance">
                    <!-- <span>{{ $t('余额') }}：</span>
                      {{ item.balance }} -->
                  </div>
                </div>
                <div class="item-right">
                  <div class="content">{{ item.contentUg }}</div>
                  <div class="time">
                    {{ new Date(item.createTime).toLocaleTimeString() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="diamond-empty" v-else>
          <img class="diamond-empty-img" src="@/assets/diamond/empty.png" />
          <p>{{ $t('暂无订购记录') }}</p>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { apiGetDiamondIncomes } from '@/api'
import { appFunc_senToken } from '@/utils/bridge'
import { CommonModule } from '@/store/modules/common'

export default {
  data() {
    return {
      incomes: [],
      depletes: [],
      recordList: ['钻石消耗记录', '订购记录'],
      active: 0,
      refreshing: false,
    }
  },
  computed: {
    language() {
      return this.$i18n.locale
    }
  },
  watch: {
    active: {
      handler: function (val) {
        if (!val) {
          this.getDepletes()
        } else {
          this.getIncomes()
        }
      },
      deep: true
    }
  },
  async created() {
    await this.getToken()
    this.getDepletes()
  },
  methods: {
    async getIncomes() {
      const { code, data } = await apiGetDiamondIncomes(1)
      if (code === 1001) {
        setTimeout(() => {
          this.incomes = data.list || []
          this.refreshing = false
        }, 500)
      }
    },
    async getDepletes() {
      const { code, data } = await apiGetDiamondIncomes(2)
      if (code === 1001) {
        setTimeout(() => {
          this.depletes = data.list || []
          this.refreshing = false
        }, 500)
      }
    },
    // 返回 
    handelBack() {
      this.$router.back()
    },
    // 获取token
    async getToken() {
      const res = await appFunc_senToken()
      console.log(res, '/////////////')
      CommonModule.setUserId(res.id)
      CommonModule.setToken(res.tokenForJs)
      CommonModule.setUserPhone(res.mobile)
      CommonModule.setUserName(res.name)
    },
    onRefresh() {
      if (this.active) {
        this.getIncomes()
      } else {
        this.getDepletes()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.diamond-record {
  height: 100vh;
  // background: #f6f7f9;
  text-align: right;
}

.diamond-record-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.diamond-date-item {
  .diamond-date {
    padding: 20px 16px 6px 16px;
    font-weight: 500;
    font-size: 14px;
    color: #888888;
  }

  .diamond-list-wrap {
    padding: 0 14px;
    background-color: #fff;
  }

  .diamond-list-item {
    color: #181818;
    padding: 25px 0;
    border-bottom: 1px solid #f6f7f9;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: inline-block;
    }
  }

  .item-left {
    margin-right: 20px;
    text-align: left;

    .count {
      font-size: 18px;
    }

    .balance {
      white-space: nowrap;
      font-size: 12px;
      margin-top: 6px;
    }
  }

  .item-right {
    .content {
      font-size: 15px;
    }

    .time {
      font-size: 12px;
      color: #888888;
      margin-top: 8px;
    }
  }
}

.diamond-empty {
  flex: 1;
  display: flex;
  height: 86vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .diamond-empty-img {
    display: block;
    width: 200px;
    height: 150px;
    margin-bottom: 10px;
  }
}

.language-left {
  &.diamond-record {
    text-align: left;
  }

  .diamond-date-item {
    .diamond-list-item {
      flex-direction: row-reverse;
    }

    .item-left {
      text-align: right;
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

::v-deep .van-tabs__wrap {
  padding-left: 14px;
  padding-right: 14px;
}

::v-deep .van-tabs__line {
  background-color: #F7B500;
  // bottom: -5px;
}

::v-deep .van-tab__text {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

::v-deep .van-tab {
  border-bottom: 2px solid #F6F7F9;
}

::v-deep .van-pull-refresh__track {
  min-height: 86vh;
}

::v-deep .van-pull-refresh {
  overflow-y: scroll;
}

.tabs {
  padding-top: 20px;
  background-color: #fff;
}
</style>
