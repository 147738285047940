import { CommonModule } from '@/store/modules/common'
import { logEvent, getAnalytics } from '@firebase/analytics'
import { initializeApp } from '@firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBybRW4o5dDHYBpa4QgVou0uMXHIpT22bQ',
  authDomain: 'tili-380606.firebaseapp.com',
  projectId: 'tili-380606',
  storageBucket: 'tili-380606.appspot.com',
  messagingSenderId: '853345433407',
  appId: '1:853345433407:web:b469f0eb7d34b5bb0e858a',
  measurementId: 'G-06KT4K30HE'
}

export default {
  data() {
    return {
      analytics: null
    }
  },
  methods: {
    // 初始化firebase
    initFirebase() {
      const app = initializeApp(firebaseConfig)
      console.log(app, 'app')
      this.analytics = getAnalytics(app)
    },
    // 上报
    report(eventName) {
      try {
        logEvent(this.analytics, eventName, {
          userid: CommonModule.userId
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 上报点击购买按钮
    reportPayClick(source) {
      switch (source) {
        case 'paypal':
          this.report('paybutton_chlick(Paypal)')
          break
        case 'card':
          this.report('paybutton_chlick(Debit or Credit Card)')
          break
        case 'google':
          this.report('paybutton_chlick(Google)')
          break
        default:
          break
      }
    },
    // 上报点击购买按钮
    reportDialogShow(source) {
      console.log('执行')
      switch (source) {
        case 'paypal':
          this.report('paymentpage(Paypal)')
          break
        case 'card':
          this.report('paymentpage(Debit or Credit Card)')
          break
        case 'google':
          this.report('paymentpage(Google)')
          break
        default:
          break
      }
    },
    // 上报购买成功
    reportPaySuccess(source) {
      switch (source) {
        case 'paypal':
          this.report('paymentsuccess(Paypal)')
          break
        case 'card':
          this.report('paymentsuccess(Debit or Credit Card)')
          break
        case 'google':
          this.report('paymentsuccess(Google)')
          break
        default:
          break
      }
    }
  }
}
