














































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getObtainDetailList } from '@/api'
import moment from 'moment'
@Component({
  name: 'GoldenbeanHarvest',
  filters: {
    dateFormat: (value: any, pattern: any) => {
      return moment(value).format(pattern)
    }
  }
})
export default class GoldenbeanHarvest extends Vue {
  [x: string]: any
  list: any = []
  // 当前语言
  @Prop({
    type: String,
    default: 'ug'
  })
  language!: string
  emptyImage = require('@/assets/harvest_nodata.png')

  created() {
    this.getObtainDetailList()
  }

  async getObtainDetailList() {
    const { data, code } = await getObtainDetailList()
    if (code == 1001) {
      this.list = data.list
      console.log(this.list, '///')
    }
  }

  get getScene() {
    return (value: any) => {
      console.log(value)
      return value.scene == 5
        ? this.$t('收获') +
            value.otherUserNickName +
            this.$t('赠送的') +
            value.giftName
        : value.scene == 4
        ? this.$t('回复') +
          value.otherUserNickName +
          this.$t('的打招呼消息获得金豆')
        : value.scene == 1 || value.scene == 3
        ? this.$t('回复') + value.otherUserNickName + this.$t('的消息获得金豆')
        : value.scene == 1009 || value.scene == 1021
        ? this.$t('签到获得') + value.amount + this.$t('金豆')
        : value.scene == 2
        ? this.$t('回复') +
          value.otherUserNickName +
          this.$t('的平台牵线消息获得金豆')
        : value.scene == 1010 || value.scene == 1022
        ? this.$t(`观看激励视频获得金豆`)
        : value.scene == 1011 || value.scene == 1023
        ? this.$t(`发布动态获得金豆`)
        : value.scene == 1024
        ? this.$t(`使用今日缘分匹配获得金豆`)
        : value.scene == 1015
        ? this.$t(`开通悬浮窗通知获得金豆`)
        : value.scene == 1016
        ? this.$t(`开启地理位置获得金豆`)
        : value.scene == 1005 || value.scene == 1017
        ? this.$t(`上传1张照片获得金豆`)
        : value.scene == 1004 || value.scene == 1018
        ? this.$t(`完善基本资料获得金豆`)
        : value.scene == 1003 || value.scene == 1019
        ? this.$t(`完成语音录制获得金豆`)
        : value.scene == 1008 || value.scene == 1020
        ? this.$t(`完成实名认证获得金豆`)
        : value.scene == 15
        ? this.$t('邀请的好友') +
          value.otherUserNickName +
          this.$t('购买钻石奖励金豆')
        : value.scene == 16
        ? this.$t('邀请的好友') +
          value.otherUserNickName +
          this.$t('收获礼物奖励金豆')
        : value.scene == 17
        ? this.$t('邀请好友') +
          value.otherUserNickName +
          this.$t('注册成功奖励金豆')
        : value.scene == 18
        ? this.$t('语音聊天奖励金豆')
        : value.scene == 19
        ? this.$t('视频聊天奖励金豆')
        : value.scene == 20
        ? this.$t('注册获得') + value.amount + this.$t('金豆')
        : value.scene == 21
        ? this.$t('聊天奖励金豆') + value.amount + this.$t('金豆')
        : value.scene == 22
        ? this.$t('聊天奖励金豆') + value.amount + this.$t('金豆')
        : value.scene == 1026
        ? this.$t('点赞动态获得') + value.amount + this.$t('金豆')
        : value.scene == 1027
        ? this.$t('评论动态获得') + value.amount + this.$t('金豆')
        : ''
    }
  }

  get Scene() {
    return (value: any) => {
      console.log(value)
      return value.scene == 5
        ? `收获xx赠送的xx`
        : value.scene == 4
        ? `回复xx的打招呼消息获得金豆`
        : value.scene == 1 || value.scene == 3
        ? `回复xx的消息获得金豆`
        : value.scene == 1009 || value.scene == 1021
        ? `签到获得x金豆`
        : value.scene == 2
        ? `回复xx的平台牵线消息获得金豆`
        : value.scene == 1010 || value.scene == 1022
        ? `观看激励视频获得金豆`
        : value.scene == 1011 || value.scene == 1023
        ? `发布动态获得金豆`
        : value.scene == 1024
        ? `使用今日缘分匹配获得金豆`
        : value.scene == 1015
        ? `开通悬浮窗通知获得金豆`
        : value.scene == 1016
        ? `开启地理位置获得金豆`
        : value.scene == 1005 || value.scene == 1017
        ? `上传1张照片获得金豆`
        : value.scene == 1004 || value.scene == 1018
        ? `完善基本资料获得金豆`
        : value.scene == 1003 || value.scene == 1019
        ? `完成语音录制获得金豆`
        : value.scene == 1008 || value.scene == 1020
        ? `完成实名认证获得金豆`
        : value.scene == 15
        ? `邀请的好友xx购买钻石奖励金豆`
        : value.scene == 16
        ? `邀请的好友xx收获礼物奖励金豆`
        : value.scene == 17
        ? `邀请好友xx注册成功奖励金豆`
        : value.scene == 1026
        ? `点赞动态获得xx金豆`
        : value.scene == 1027
        ? `评论动态获得xx金豆`
        : ''
    }
  }
}
