






























































import { Component, Vue } from 'vue-property-decorator'
import { appFunc_callExit, appFunc_senToken } from '@/utils/bridge'
import { getIntimacyByH5 } from '@/api'
import { CommonModule } from '@/store/modules/common'
@Component({
  name: 'intimacyRule'
})
export default class extends Vue {
  toUid = this.$route.query.toUid as string
  currentUserHeadImage = ''
  currentUserNickname = ''
  intimacy = ''
  otherHeadImage = ''
  otherUserNickname = ''
  intimacyConfig = {
    addIntimacyAmount: '',
    coinUnit: '',
    intimacyMaxValue: '',
    notAddTimeP: '',
    reduceIntimacyAmount: '',
    reduceIntimacyInterval: '',
    videoUnlockIntimacyLimitAmount: '',
    voiceUnlockIntimacyLimitAmount: ''
  }
  handelBack() {
    appFunc_callExit()
  }

  async getToken() {
    const res = await appFunc_senToken()
    console.log(res, '/////////////')
    CommonModule.setUserId(res.id)
    CommonModule.setToken(res.tokenForJs)
    CommonModule.setUserPhone(res.mobile)
    CommonModule.setUserName(res.name)
  }
  async created() {
    await this.getToken()
    const { data } = await getIntimacyByH5({ toUid: this.toUid })
    this.currentUserHeadImage = data.currentUserHeadImage
    this.currentUserNickname = data.currentUserNickname
    this.intimacy = data.intimacy
    this.otherHeadImage = data.otherHeadImage
    this.otherUserNickname = data.otherUserNickname
    this.intimacyConfig = data.intimacyConfig
  }
}
