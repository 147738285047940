<template>
  <div class="del-user">
    <p>App Name: Tili</p>
    <p>Developer Name: allo</p>
    <h3>Please enter your login account</h3>
    <input
      v-model="account"
      type="text"
      placeholder="Please enter your login account"
    />
    <p>
      1. After the account deletion is submitted, all data such as gold beans,
      diamonds, points, dynamics, chat messages, etc. under the account will be
      cleared
    </p>
    <p>
      2. The data will be retained for 15 days. You cannot log in to the APP
      within 15 days. After 15 days, the data will be completely deleted
    </p>
    <p>
      3. The account deletion cannot be revoked after the account deletion
      application is submitted
    </p>
    <p>
      4. Account deletion steps
    </p>
    <p style="text-indent: 1rem;">
      ① Write your Tili account and confirm the deletion of the account
    </p>
    <p style="text-indent: 1rem;">
      ② Wait for 15 days for the account to be deleted
    </p>
    <div class="submit-btn" @click="submitAccout">
      Confirm
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      account: ''
    }
  },
  methods: {
    submitAccout() {
      if (!this.account) return
      this.$toast('success')
      this.account = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.del-user {
  padding: 40px 28px 0;
  p {
    margin-bottom: 10px;
    line-height: 20px;
  }
  h3 {
    font-weight: 600;
    margin: 40px 0 10px;
  }
  input {
    width: 250px;
    border: 1px solid #ccc;
    padding: 6px 16px;
    margin-bottom: 40px;
    border-radius: 6px;
  }
  .submit-btn {
    text-align: center;
    width: 200px;
    height: 48px;
    line-height: 48px;
    background: #ffeb3c;
    border-radius: 16px 16px 16px 16px;
    margin: 20px auto;
    font-size: 20px;
  }
}
</style>
