import { CommonModule } from '@/store/modules/common'
import { codaURL } from '@/config'
import { apiCreateOrder, apiPayNotify } from '@/api'

export default {
  data() {
    return {
      codaOrderInfo: {},
      codaCountryId: ''
    }
  },
  created() {
    // this.initPaypal()
  },
  methods: {
    // 点击code支付 --clickCodaPay
    async clickCodaPay(countryId) {
      // console.log(paypalRes.data, '-----------------')7167898349966065857
      // window.location.href = `${codaURL}?txn_id=7165446859646071984`
      // window.location.href = `${codaURL}?txn_id=7167906177986065303&browser_type=mobile-web&type=3`
      this.codaCountryId = countryId
      // 创建订单
      const { code, data } = await apiCreateOrder({
        rechargeConfigId: this.ruleList[this.currentIndex].id,
        tradePlatform: 6,
        pageFrom: this.$route.query.pageform || '',
        countryPayConfigId: countryId,
        codaReturnUrl: `${
          window.location.origin
        }/#/diamond?codaCountryId=${countryId}&payProductId=${
          this.ruleList[this.currentIndex].id
        }&transactionId={transactionId}&orderId={orderId}`
      })
      // console.log(code, data.payOrderId)
      if (code === 1001 && data.txnId && data.txnId != 0) {
        this.codaOrderInfo = data
        // window.location.href = `${codaURL}?txn_id=${data.txnId}`
        // console.log(
        //   `${codaURL}?txn_id=${data.txnId}&browser_type=mobile-web&type=3`
        // )
        // 跳转支付页面
        window.location.href = `${codaURL}?txn_id=${data.txnId}&browser_type=mobile-web&type=3`
      }
    },
    // coda支付
    async codaPayNotify(params) {
      const { code, data } = await apiPayNotify({
        payOrderId: params.orderId,
        payProductId: params.payProductId,
        payType: 'coda',
        tradeNo: params.transactionId,
        uid: CommonModule.userId || '8875',
        countryPayConfigId: params.codaCountryId
      })
      console.log(code, data)
      if (code === 1001) {
        this.isPaySuccess = true
        // this.$toast({
        //   message: this.$t('支付成功'),
        //   duration: 5000
        // })
        this.getRuleList()
      }
    }
  }
}
