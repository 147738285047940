<template>
  <div>
    <div class="realPerson" v-if="authStatus">
      <img :src="authStatus == 'success' ? require('@/assets/success.png') : require('@/assets/error.png')" />
      <p>{{ $t(authStatus == 'success' ? '支付成功' : '支付失败') }}</p>
    </div>
  </div>
</template>
<script>
import { apiPayNotify } from '@/api'
import { CommonModule } from '@/store/modules/common'
export default {
  data() {
    return {
      authStatus: null,
      authUrlIcon: ''
    }
  },
  methods: {
    // 切换语言
    getLanguage(language) {
      this.$i18n.locale = language
    },
  },

  created() {
    const params = this.$route.query
    // 获取当前语言
    const data = this.$route.query.language || 'ug'
    this.language = data
    this.getLanguage(data)
    CommonModule.setLanguage(data)
    this.$nextTick(async () => {
      if (this.$route.query.transactionId) {
        const { code, data } = await apiPayNotify({
          payOrderId: params.orderId,
          payProductId: params.payProductId,
          payType: 'coda',
          tradeNo: params.transactionId,
          uid: CommonModule.userId || '8875',
          countryPayConfigId: params.codaCountryId
        })
        if (code == 1001) {
          if (data.handleResult) {
            this.authStatus = 'success'
            this.authUrlIcon = require('@/assets/success.png')
          } else {
            this.authStatus = 'error'
            this.authUrlIcon = require('@/assets/error.png')
          }
        }
      }
    })
  },
}
</script>
<style scoped lang="scss">
.left_back {
  width: 30px;
  height: 30px;
}

.realPerson {
  width: 100vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  box-sizing: border-box;

  img {
    width: 132px;
    height: 132px;
  }

  p {
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
  }
}
</style>