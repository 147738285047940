import { CommonModule } from '@/store/modules/common'
import { codaURL } from '@/config'
import { apiCreateVipOrder, apiPayNotify } from '@/api'
import { appFunc_callPayVipBack } from '@/utils/bridge.ts'

export default {
  data() {
    return {
      codaOrderInfo: {},
      codaCountryId: '',
      vipId: ''
    }
  },
  created() {
    // this.initPaypal()
  },
  methods: {
    // 点击code支付 --clickCodaPay
    async clickCodaPay(params) {
      // console.log(paypalRes.data, '-----------------')7167898349966065857
      // window.location.href = `${codaURL}?txn_id=7165446859646071984`
      // window.location.href = `${codaURL}?txn_id=7167906177986065303&browser_type=mobile-web&type=3`
      this.codaCountryId = params.countryId
      this.vipId = params.vipId
      this.olderPrice = params.olderPrice
      // 创建订单
      const { code, data } = await apiCreateVipOrder({
        rechargeConfigId: +params.vipId, // 会员包 id
        tradePlatform: 6,
        // pageFrom: this.$route.query.pageform || '', // pageform 充值来源 暂时不传
        countryPayConfigId: +params.countryId, // countryId 国家id
        codaReturnUrl: `${window.location.origin}/#/googlePay?id=${params.vipId}&codaCountryId=${params.countryId}&price=${params.olderPrice}`
      })
      // console.log(code, data.payOrderId)
      if (code === 1001 && data.txnId && data.txnId != 0) {
        this.codaOrderInfo = data
        // 跳转支付页面
        window.location.href = `${codaURL}?txn_id=${data.txnId}&browser_type=mobile-web&type=3`
        // console.log(
        //   `${codaURL}?txn_id=${data.txnId}&browser_type=mobile-web&type=3`
        // )
      }
    },
    // coda 查询支付状态 （支付回调）
    async codaPayNotify(params) {
      const { code, data, message } = await apiPayNotify({
        payOrderId: params.orderId,
        payProductId: params.payProductId,
        payType: 'coda',
        tradeNo: params.transactionId,
        uid: CommonModule.userId || '8875',
        countryPayConfigId: params.codaCountryId
      })
      console.log(code, data)
      if (code === 1001) {
        // this.isPaySuccess = true
        console.log('支付成功')
        this.$toast({
          message: this.$t('支付成功'),
          duration: 5000
        })
        appFunc_callPayVipBack({ type: 'success', callBackStatus: 1 })
        // this.$toast({
        //   message: this.$t('支付成功'),
        //   duration: 5000
        // })
        // this.getRuleList()
      } else {
        console.log('支付失败')
        appFunc_callPayVipBack({
          type: 'error',
          callBackStatus: 0,
          errMsg: message
        })
      }
    }
  }
}
