import { loadScript } from '@paypal/paypal-js'
import { clientId } from '@/config'
import { CommonModule } from '@/store/modules/common'
import { appFunc_callPayVipBack } from '@/utils/bridge.ts'

import {
  apiCreateVipOrder,
  apiPayNotify,
  apiGetAccessToken,
  apiPayPalCreateOrder,
  apiMarkFailReason
} from '@/api'
import { debounce } from '@/utils/index'

export default {
  data() {
    return {
      payWays: ['paypal', 'card'],
      // payWays: [],
      paypalOrderInfo: {},
      btnStyle: {
        paypal: {
          color: 'gold'
        },
        card: {
          color: 'black'
        }
      },
      params: {},
      paySource: ''
    }
  },
  created() {
    // this.initPaypal()
  },
  methods: {
    // 初始化paypal
    async initPaypal(params) {
      this.params = params
      let paypal
      try {
        paypal = await loadScript({
          'client-id': clientId,
          components: 'buttons,funding-eligibility',
          // locale: this.$i18n.locale === 'turki' ? 'en_TR' : 'ar_SA',
          'disable-funding': 'paylater'
        })
      } catch (error) {
        console.error('failed to load the PayPal JS SDK script', error)
      }
      console.log(paypal, '--------------------')
      if (paypal) {
        try {
          // paypal.Marks().render('#payment-paypal')
          // paypal.CardFields({
          //   style: {
          //     input: {
          //       color: 'red'
          //     }
          //   }
          // })
          this.payWays.forEach(item => {
            const button = paypal.Buttons({
              fundingSource: item,
              style: {
                borderRadius: 10,
                ...this.btnStyle[item]
              },
              createOrder: this.createOrder,
              onApprove: this.payApprove,
              onCancel: this.payCancel,
              onError: this.payError
            })
            if (button.isEligible()) {
              button.render(`#payment-${item}`)
            }
          })
        } catch (error) {
          console.error('failed to render the PayPal Buttons', error.message)
        }
      }
    },
    // 创建订单
    async createOrder(query) {
      console.log(query, '---------------------------------------------------')
      // console.log('创建订单回调', this.params, this.params.paymentSource)
      this.paySource = query.paymentSource
      this.reportPayClick('paypal')
      const tokenRes = await apiGetAccessToken()
      if (tokenRes.code != 1001) return
      const paypalRes = await apiPayPalCreateOrder(
        {
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.params.olderPrice // 会员包 金额
              }
            }
          ],
          intent: 'CAPTURE'
        },
        tokenRes.data
      )
      if (paypalRes.status != 201) {
        console.log('paypal创建订单失败')
        return
      }
      // console.log(paypalRes.data, '-----------------')
      const { code, data } = await apiCreateVipOrder({
        rechargeConfigId: this.params.vipId,
        // tradePlatform: this.params.paymentSource === 'paypal' ? 4 : 5,
        tradePlatform: query.paymentSource === 'paypal' ? 4 : 5
        // pageFrom: this.$route.query.pageform || ''
      })
      // console.log(code, data.payOrderId)

      if (paypalRes.data.status === 'CREATED' && code === 1001) {
        this.paypalOrderInfo = data
        this.reportDialogShow(this.params.paymentSource)
        return paypalRes.data.id
      }
    },
    // 支付成功
    async payApprove(params) {
      console.log('支付成功回调', params)
      // const tokenRes = await apiGetAccessToken()
      // if (tokenRes.code != 1001) return
      // const errRes = await apiCaptureErrorOrder(params.orderID, tokenRes.data)
      // console.log(errRes, '-----------------------------------errRes')
      let payProductId = 0
      if (this.paySource == 'card') {
        const payItem = this.payConfig.filter(item => item.payType == -2)
        payProductId = payItem.id
      } else {
        const payItem = this.payConfig.filter(item => item.payType == -3)
        payProductId = payItem.id
      }
      const { code, data } = await apiPayNotify({
        payOrderId: this.paypalOrderInfo.payOrderId,
        payProductId,
        payType: 'paypal',
        tradeNo: params.orderID,
        uid: CommonModule.userId || '8875'
      })
      console.log(code, data)
      if (code === 1001) {
        // this.isPaySuccess = true
        this.$toast({
          message: this.$t('支付成功'),
          duration: 5000
        })
        appFunc_callPayVipBack({ type: 'success', callBackStatus: 1 })
        this.reportPaySuccess(params.paymentSource)
      }
    },
    // 取消支付
    payCancel(params) {
      apiPayNotify({
        payOrderId: this.paypalOrderInfo.payOrderId,
        payProductId: this.ruleList[this.currentIndex].id,
        payType: 'paypal',
        tradeNo: params.orderID,
        uid: CommonModule.userId
      })
      apiMarkFailReason({
        failReason: '用户取消支付',
        orderNo: this.paypalOrderInfo.payOrderId
      })
    },
    // 支付失败
    payError(err) {
      console.log('支付失败回调', err.message)
      appFunc_callPayVipBack({
        type: 'error',
        callBackStatus: 0,
        errMsg: err.message
      })
      if (this.params.id) {
        apiMarkFailReason({
          failReason: err.message,
          orderNo: this.params.id
        })
      }
    }
  }
}
